import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { CommonService } from './common.service';

const httpOptions:any = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class ContactsService {

  public hostName : string;
  public Urls : any;

  constructor(private http: HttpClient,
    private commonService : CommonService) {
    this.hostName = this.commonService.getServerPath();
    this.Urls = {
      'getAll' : this.hostName+'api/contacts/getAll',
      'contacts' : this.hostName+'api/contacts',
      'clientContacts' : this.hostName+'api/client-contacts',
      'create' : this.hostName+'api/contacts/create',
      'update' : this.hostName+'api/contacts/update',
      'clientContactKey' : this.hostName+'api/client-contact-detail'
    }
  }

  private extractData(res: Response) {
    let body = res;
    return body || { };
  }

  getAllContacts() : Observable<any> {
    console.log(this.Urls.getAll);
    return this.http.get<ResponseData>(this.Urls.getAll)
    .pipe( tap( (res) => res));
  }

  getContactByKey(data) : Observable<any> {
    let getPath =  this.Urls.contacts+"/"+data;
    return this.http.get<ResponseData>(getPath)
    .pipe( tap( (res) => res));
  }

  getContactsByClient(idData : number):Observable<any> {
    return this.http.get<ResponseData>(this.Urls.clientContacts+"/"+idData)
    .pipe( tap( (res) => res));
  }

  createContact(data) : Observable<any> {
    return this.http.post<ResponseData>(this.Urls.create, data)
    .pipe( tap( (res) => res ) );
  }

  getClientContactByKey(id, client_id) : Observable<any> {
    let getPath =  this.Urls.clientContactKey+"/"+id+"/"+client_id;
    return this.http.get<ResponseData>(getPath)
    .pipe( tap( (res) => res));
  }

  updateContact(data) : Observable<any> {
    return this.http.post<ResponseData>(this.Urls.update, data)
    .pipe( tap( (res) => res ) );
  }

  deleteContactById(idData :number) :Observable<any> {
    return this.http.delete<ResponseData>(this.Urls.contacts+"/"+idData, httpOptions).pipe(
      tap((res) => res));
  }

}

export interface ResponseData {
  success: boolean;
  message: string;
  result : any;
}
