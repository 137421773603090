import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { CommonService } from './common.service';

const httpOptions:any = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class NewsService {
  
  
  public hostName : string;
  public Urls : any;

  constructor(private http: HttpClient,
    private commonService : CommonService) {
    this.hostName = this.commonService.getServerPath();
    this.Urls = {
        'listAll' : this.hostName+'api/news/getAll',
        'news' : this.hostName+'api/news/'
    }

  }
    

  private extractData(res: Response) {
    let body = res;
    return body || { };
  }

  getAllNews() : Observable<any> {
    return this.http.get<ResponseData>(this.Urls.listAll)
    .pipe( tap( (res) => res));
  }

  getNewsByKey(data) : Observable<any> {
    let getPath =  this.Urls.news+data;
    return this.http.get<ResponseData>(getPath)
    .pipe( tap( (res) => res));
  }

  createNews(data):Observable<any> {
    return this.http.post<ResponseData>(this.Urls.news, data).pipe(
      tap((res) => res));
  }

  updateNewsById(data):Observable<any> {
    return this.http.put<ResponseData>(this.Urls.news, data).pipe(
      tap((res) => res));
  }

  deleteNewsById(idData :number):Observable<any> {
    return this.http.delete<ResponseData>(this.Urls.news+idData, httpOptions).pipe(
      tap((res) => res));
  }

}

export interface ResponseData {
  success: boolean;
  message: string;
  result : any;
}