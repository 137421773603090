import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { CommonService } from './common.service';

const httpOptions:any = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class ContactRatesService {

  public hostName : string;
  public Urls : any;

  constructor(private http: HttpClient,
    private commonService : CommonService) {
    this.hostName = this.commonService.getServerPath();
    this.Urls = {
      'getAll' : this.hostName+'api/contact-rates/getAll',
      'contactRates' : this.hostName+'api/contact-rates',
      'contactRatesDeliveryDisp' : this.hostName+'api/contact-rates-delivery-display',
      'checkOverlap' : this.hostName+'api/contact-rates-check-overlap'
    }
  }

  private extractData(res: Response) {
    let body = res;
    return body || { };
  }

  getAllContactRates() : Observable<any> {
    return this.http.get<ResponseData>(this.Urls.getAll)
    .pipe( tap( (res) => res));
  }

  getContactRateByKey(data) : Observable<any> {
    let getPath =  this.Urls.contactRates+"/"+data;
    return this.http.get<ResponseData>(getPath)
    .pipe( tap( (res) => res));
  }

  createContactRates(data):Observable<any> {
    return this.http.post<ResponseData>(this.Urls.contactRates, data).pipe(
      tap((res) => res));
  }

  updateContactRatesById(data):Observable<any> {
    return this.http.put<ResponseData>(this.Urls.contactRates, data).pipe(
      tap((res) => res));
  }

  deleteContactRatesById(idData :number):Observable<any> {
    return this.http.delete<ResponseData>(this.Urls.contactRates+"/"+idData, httpOptions).pipe(
      tap((res) => res));
  }

  getDeliveryTypeDetails(Data):Observable<any> {
    return this.http.post<ResponseData>(this.Urls.contactRatesDeliveryDisp, Data).pipe(
      tap((res) => res));
  }

  checkOverlap(Data):Observable<any> {
    return this.http.post<ResponseData>(this.Urls.checkOverlap, Data).pipe(
      tap((res) => res));
  }
  
}

export interface ResponseData {
  success: boolean;
  message: string;
  result : any;
}
