import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { CommonService } from './common.service';


@Injectable({
  providedIn: 'root'
})

export class HandlersService {

  public hostName : string;
  public Urls : any;
  public httpOptions : any; 

  constructor(private http: HttpClient,
    private commonService : CommonService) {
    this.hostName = this.commonService.getServerPath(); 
    this.Urls = {
      'handlers' : this.hostName+'api/handlers',
      'updateFields' : this.hostName+'api/handlers/update-fields',
    }
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.httpOptions = {
        headers: new HttpHeaders({
          'Content-Type' :  'application/json',
        })        
    }; 
  }
   

  private extractData(res: Response) { 
    let body = res;
    return body || { };
  }

  getHandlers() : Observable<any> {
    return this.http.get<ResponseData>(this.Urls.handlers, this.httpOptions)
    .pipe( tap( (res) => { res; console.log(res);}));
  }

  createHandler(data) :Observable<any> {
    return this.http.post<ResponseData>(this.Urls.handlers, data, this.httpOptions).pipe(
      tap((res) => res));
  }

  getHandlerById(id):Observable<ResponseData> {
    return this.http.get<ResponseData>(this.Urls.handlers+"/"+id).pipe(
      tap((res) => res));
  }

  updateHandlerById(data):Observable<any>  {
    return this.http.put<ResponseData>(this.Urls.handlers, data, this.httpOptions).pipe(
      tap((res) => res));
  }

  deleteHandlerById(idData :number):Observable<any>  {
    return this.http.delete<ResponseData>(this.Urls.handlers+"/"+idData, this.httpOptions).pipe(
      tap((res) => res));
  }

  updateHandlerFieldsById(data):Observable<any>  {
    return this.http.post<ResponseData>(this.Urls.updateFields, data, this.httpOptions).pipe(
      tap((res) => res));
  }

  

}


export interface ResponseData {
  success: boolean;
  message: string;
  result : any;
}