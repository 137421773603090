import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { CommonService } from './common.service';

const httpOptions:any = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class CountryZonesService {

  public hostName : string;
  public Urls : any;

  constructor(private http: HttpClient,
    private commonService : CommonService) {
    this.hostName = this.commonService.getServerPath();
    this.Urls = {
      'getAll' : this.hostName+'api/country-zones/getAll',
      'countryZones' : this.hostName+'api/country-zones',
      'Zones4ParcelType' : this.hostName+'api/country-zones-4-parceltype',
    }
  }

  private extractData(res: Response) {
    let body = res;
    return body || { };
  }

  getAllCountryZones() : Observable<any> {
    return this.http.get<ResponseData>(this.Urls.getAll)
    .pipe( tap( (res) => res));
  }

  getCountryZoneByKey(data) : Observable<any> {
    let getPath =  this.Urls.countryZones+"/"+data;
    return this.http.get<ResponseData>(getPath)
    .pipe( tap( (res) => res));
  }

  createCountryZones(data):Observable<any> {
    return this.http.post<ResponseData>(this.Urls.countryZones, data).pipe(
      tap((res) => res));
  }

  updateCountryZonesById(data):Observable<any> {
    return this.http.put<ResponseData>(this.Urls.countryZones, data).pipe(
      tap((res) => res));
  }

  deleteCountryZonesById(idData :number):Observable<any> {
    return this.http.delete<ResponseData>(this.Urls.countryZones+"/"+idData, httpOptions).pipe(
      tap((res) => res));
  }

  getZoneForParcelType(data):Observable<any> {
    return this.http.post<ResponseData>(this.Urls.Zones4ParcelType, data).pipe(
      tap((res) => res));
  }

}

export interface ResponseData {
  success: boolean;
  message: string;
  result : any;
}
