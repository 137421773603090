import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { CommonService } from './common.service';

const httpOptions:any = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class ClientRatesService {

  public hostName : string;
  public Urls : any;

  constructor(private http: HttpClient,
    private commonService : CommonService) {
    this.hostName = this.commonService.getServerPath();
    this.Urls = {
      'getAll' : this.hostName+'api/client-rates/getAll',
      'clientRates' : this.hostName+'api/client-rates',
      'allClientRates' : this.hostName+'api/client-client-rates',
      'price' : this.hostName+'api/client-rates-price'
    }
  }

  private extractData(res: Response) {
    let body = res;
    return body || { };
  }

  getAllClientRates() : Observable<any> {
    return this.http.get<ResponseData>(this.Urls.getAll)
    .pipe( tap( (res) => res));
  }

  getClientRateByKey(data) : Observable<any> {
    let getPath =  this.Urls.clientRates+"/"+data;
    return this.http.get<ResponseData>(getPath)
    .pipe( tap( (res) => res));
  }

  createClientRates(data):Observable<any> {
    return this.http.post<ResponseData>(this.Urls.clientRates, data).pipe(
      tap((res) => res));
  }

  updateClientRatesById(data):Observable<any> {
    return this.http.put<ResponseData>(this.Urls.clientRates, data).pipe(
      tap((res) => res));
  }

  deleteClientRatesById(idData :number):Observable<any> {
    return this.http.delete<ResponseData>(this.Urls.clientRates+"/"+idData, httpOptions).pipe(
      tap((res) => res));
  }

  getClientRateByClient(idData : number):Observable<any> {
    return this.http.get<ResponseData>(this.Urls.allClientRates+"/"+idData)
    .pipe( tap( (res) => res));
  }

  getClientRatesPrice(data):Observable<any> {
    return this.http.post<ResponseData>(this.Urls.price, data)
    .pipe( tap((res) => (res)));
  }

}

export interface ResponseData {
  success: boolean;
  message: string;
  result : any;
}
