import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { CommonService } from './common.service';

const httpOptions:any = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class DefaultRatesService {

  public hostName : string;
  public Urls : any;

  constructor(private http: HttpClient,
    private commonService : CommonService) {
    this.hostName = this.commonService.getServerPath();
    this.Urls = {
      'getAll' : this.hostName+'api/default-rates/getAll',
      'defaultRates' : this.hostName+'api/default-rates',
      'price' : this.hostName+'api/default-rates-price'
    }
  }

  private extractData(res: Response) {
    let body = res;
    return body || { };
  }

  getAllDefaultRates() : Observable<any> {
    return this.http.get<ResponseData>(this.Urls.getAll)
    .pipe( tap( (res) => res));
  }

  getDefaultRateByKey(data) : Observable<any> {
    let getPath =  this.Urls.defaultRates+"/"+data;
    return this.http.get<ResponseData>(getPath)
    .pipe( tap( (res) => res));
  }

  createDefaultRates(data):Observable<any> {
    return this.http.post<ResponseData>(this.Urls.defaultRates, data).pipe(
      tap((res) => res));
  }

  updateDefaultRatesById(data):Observable<any> {
    return this.http.put<ResponseData>(this.Urls.defaultRates, data).pipe(
      tap((res) => res));
  }

  deleteDefaultRatesById(idData :number):Observable<any> {
    return this.http.delete<ResponseData>(this.Urls.defaultRates+"/"+idData, httpOptions).pipe(
      tap((res) => res));
  }

  getDefaultRatesPrice(data):Observable<any> {
    return this.http.post<ResponseData>(this.Urls.price, data)
    .pipe( tap( (res) => res ));
  }

}

export interface ResponseData {
  success: boolean;
  message: string;
  result : any;
}
